import { Component, createApp } from 'vue';
import { Router } from 'vue-router';
import { VueQueryPlugin } from 'vue-query';

import { Quasar, Notify } from 'quasar'
import iconSet from 'quasar/icon-set/fontawesome-v6'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import 'quasar/dist/quasar.css' // Import Quasar's CSS file

import { globalProperties } from './globalProperties';
import { pinia } from '@/stores';
import { setHTTPHeader, setHTTPBaseURL } from '@/services/http.service';
import AuthService from '@/services/auth.service';

const token = AuthService.getToken();

if (token) {
  setHTTPHeader({ Authorization: token });
}

export const setupEntryPoint = (rootComponent: Component, router: Router, baseAPIURL: string) => {
  const app = createApp(rootComponent);

  app.use(router);
  app.use(pinia);
  app.use(VueQueryPlugin);
  app.use(Quasar, {
    plugins: {
      Notify
    }, // import Quasar plugins and add here
    iconSet: iconSet,
    /*
    config: {
      notify: {},
    }
    */
  })
  app.config.globalProperties = { ...app.config.globalProperties, ...globalProperties };

  setHTTPBaseURL(baseAPIURL);

  app.mount('#app');
};
