import axios from 'axios';
import type { HeadersDefaults } from 'axios';
import { API_URL } from '@/configs';
import AuthService from '@/services/auth.service';

const http = axios.create({
  baseURL: API_URL,
});
declare module "axios" {
  export interface AxiosResponse {
    success: boolean;
  }
  export interface AxiosRequestConfig {
    disableToastError?: boolean;
  }
}

http.interceptors.response.use(
  async (response) => {
    const data = response.data;

    return { ...response, data, success: true};
  },
  (error) => {
    if (error.status === 401) {
      AuthService.clearCache();
    }

    const errMessage: string = error?.response?.data || 'Unknown Error';
    return { errMessage, success: false };
  },
);

const setHTTPHeader = (header: HeadersDefaults['common']) => {
  http.defaults.headers.common = { ...http.defaults.headers.common, ...header };
};

const setHTTPBaseURL = (url: string) => {
  http.defaults.baseURL = url;
};

export { http, setHTTPHeader, setHTTPBaseURL };
